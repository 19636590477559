<template>
  <div :class="$style.container">
    <p :class="$style.title">{{ $t("auth.login_using") }}:</p>
    <div :class="$style.socials">
      <SharedButtonPopupButton
        id="social_google"
        size="s"
        data-test="social-google-btn"
        @click="handle('google')"
      >
        <IconGoogle />
      </SharedButtonPopupButton>
      <SharedButtonPopupButton
        id="social_vk"
        size="s"
        data-test="social-vk-btn"
        @click="handle('vk')"
      >
        <IconVk />
      </SharedButtonPopupButton>
      <SharedButtonPopupButton
        id="social_yandex"
        size="s"
        data-test="social-yandex-btn"
        @click="handle('yandex')"
      >
        <IconYandex />
      </SharedButtonPopupButton>
      <SharedButtonPopupButton
        id="social_telegram"
        size="s"
        data-test="social-telegram-btn"
        @click="handle('telegram')"
      >
        <IconTg />
      </SharedButtonPopupButton>
      <SharedButtonPopupButton
        id="social_steam"
        size="s"
        data-test="social-steam-btn"
        @click="handle('steam')"
      >
        <IconSteam />
      </SharedButtonPopupButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { SocialNames } from "~/types/auth";

const userStore = useUserStore();
const blocked = ref(false);
const social = ref<SocialNames>();
const route = useRoute();

async function handle(name: SocialNames) {
  if (blocked.value) return;
  try {
    blocked.value = true;
    social.value = name;
    const redirectUri = userStore.getSocialRedirectUri(name);
    const { url } = await userStore.getSocialAuthUrl(name, redirectUri);
    const state: string = `&state=${encodeURIComponent(
      JSON.stringify({ path: route.path }),
    )}`;
    reloadNuxtApp({
      path: url + state,
    });
  } catch (e) {
    /* empty */
  } finally {
    blocked.value = false;
  }
}
</script>

<style lang="scss" module>
@mixin button {
  height: 44px;
  width: 44px;
}
.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2lg);
}
.title {
  color: var(--general-transparent-light-100);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  letter-spacing: -0.014px;
}
.socials {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-xs);
  :global(svg) {
    width: 28px;
  }
}

:global(#social_google) {
  @include button;
  background-color: #db4537;
  &:hover {
    background-color: #d33f31;
  }
  &:active {
    background-color: #d33f31;
  }
}
:global(#social_vk) {
  @include button;
  background-color: #0077ff;
  &:hover {
    background-color: #0171f1;
  }
  &:active {
    background-color: #0171f1;
  }
}
:global(#social_steam) {
  @include button;
  background-color: #295ea7;
  &:hover {
    background-color: #255598;
  }
  &:active {
    background-color: #255598;
  }
}
:global(#social_telegram) {
  @include button;
  background-color: #0088cc;
  &:hover {
    background-color: #007ebd;
  }
  &:active {
    background-color: #007ebd;
  }
  path {
    fill: var(--general-white);
  }
}
:global(#social_yandex) {
  @include button;
  background-color: #fc3f1e;
  &:hover {
    background-color: #fc2803;
  }
  &:active {
    background-color: #fc2803;
  }
}
</style>
