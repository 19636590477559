<template>
  <Block
    v-if="
      userStore.userIsAuthorized &&
      productStore.lastOrders &&
      productStore.lastOrders.length > 0
    "
    :class="$style['last-topup']"
  >
    <section :class="$style['last-topup-descr']">
      <MainBlockHeader :title="t('home.last_topups')" />
    </section>
    <section
      :class="$style['last-topup-list']"
      data-test="last-topup-list"
    >
      <ActionCard
        v-for="(topup, i) of productStore.lastOrders"
        :key="i"
        :size="cardSize"
        :img="topup.game.logo"
        :action="topup.game.title"
        :caption="getDescription(topup)"
        :with-arrow="true"
        :data-test="`last-topup-list-${i}`"
        @click="repeatOrder(topup)"
      />
    </section>
    <PopupContainer
      v-if="showOrderConfirmForm"
      @hide="showOrderConfirmForm = false"
    >
      <FormOrderConfirm @hide="showOrderConfirmForm = false">
        <template #title>
          {{ $t("home.repeat_order") }}
        </template>
      </FormOrderConfirm>
    </PopupContainer>
    <PopupContainer
      v-if="showUserDataForm"
      @hide="showUserDataForm = false"
    >
      <FormsFormOrderUserData @hide="showUserDataForm = false" />
    </PopupContainer>
  </Block>
</template>

<script setup lang="ts">
import Block from "~/components/Shared/Block/Block.vue";
import MainBlockHeader from "~/components/Home/MainBlockHeader.vue";
import ActionCard from "~/components/Shared/ActionCard/index.vue";
import { useProductsStore } from "../../stores/products";
import FormOrderConfirm from "../Forms/FormOrderConfirm.vue";
import PopupContainer from "~/components/Shared/Popup/PopupContainer.vue";
import { useUserStore } from "../../stores/user";
import type { OrderHistory } from "~/api_gen";

const { t } = useI18n();
const { isMobile, isTablet } = useDevice();

const cardSize = computed(() => {
  if (isMobile.value) {
    return "s";
  } else if (isTablet.value) {
    return "m";
  } else {
    return "xl";
  }
});
const userStore = useUserStore();
const productStore = useProductsStore();
onBeforeMount(async () => {
  if (userStore.userIsAuthorized) {
    productStore.getLastOrders();
  }
});

function getDescription(topup: OrderHistory) {
  return topup.orderProducts.map((order) => order.title).join(" • ");
}

const { showOrderConfirmForm, showUserDataForm } = storeToRefs(productStore);
const repeatOrder = async (topup: OrderHistory) => {
  await productStore.setRepeatedOrderData(topup);
  showOrderConfirmForm.value = true;
};
</script>

<style module lang="scss">
.last-topup {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-5xl);
  padding: var(--spacing-5xl) var(--spacing-6xl) var(--spacing-6xl)
    var(--spacing-6xl);
  margin-top: var(--spacing-xl);

  @media screen and (max-width: $tablet) {
    margin-top: var(--spacing-2lg);
    padding: var(--spacing-5xl) var(--spacing-4xl) var(--spacing-4xl)
      var(--spacing-4xl);
    gap: var(--spacing-4xl);
  }

  @media screen and (max-width: $mobile) {
    margin-top: var(--spacing-xl);
    gap: var(--spacing-4xl);
    padding: var(--spacing-3xl) var(--spacing-2lg) var(--spacing-2lg)
      var(--spacing-2lg);
  }

  &-descr {
    display: flex;
    justify-content: space-between;
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: var(--spacing-2lg);
    grid-row-gap: var(--spacing-2lg);

    @media screen and (max-width: $mobile) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}
</style>
