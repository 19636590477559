<template>
  <div
    :class="[
      $style['order-topup__info'],
      mobileColor ? $style['mobile-color'] : '',
    ]"
  >
    <div :class="$style['order-topup__info-header']">
      {{ t(`topup.topup_info`) }}
    </div>
    <div :class="$style['order-topup__info-blocks']">
      <!-- Блок id и аватарки для StandoffOrder -->
      <div
        v-if="order.resourcetype === 'StandoffOrder'"
        :class="$style['info-block']"
      >
        <img
          v-if="order.gameAvatar"
          :class="$style['block-img']"
          :src="order.gameAvatar"
        />
        <div :class="$style['text-data']">
          <div :class="$style['text-data']">
            <div :class="$style['desc']">{{ t(`topup.gamer_id`) }}</div>
            <div :class="$style['desc-data']">{{ order.gameAccountId }}</div>
          </div>
        </div>
      </div>

      <!-- Блок id и аватарки для Moogold -->
      <div
        v-if="order.resourcetype === 'MooGoldOrder'"
        :class="$style['info-block']"
      >
        <img
          :class="$style['block-img']"
          :src="order.game.logo"
        />
        <div :class="$style['text-data']">
          <div :class="$style['text-data']">
            <div :class="$style['desc']">{{ t(`topup.gamer_id`) }}</div>
            <div :class="$style['desc-data']">{{ order.moogoldUserId }}</div>
          </div>
        </div>
      </div>

      <!-- Блок логина Стима -->
      <div
        v-if="order.resourcetype === 'SteamOrder'"
        :class="$style['info-block']"
      >
        <div :class="$style['text-data']">
          <div :class="$style['desc']">{{ t(`topup.gamer_login`) }}</div>
          <div :class="$style['desc-data']">{{ order.steamAccountId }}</div>
        </div>
      </div>

      <!-- Блок почты -->
      <div
        v-if="order.email"
        :class="$style['info-block']"
      >
        <div :class="$style['text-data']">
          <div :class="$style['desc']">{{ t(`auth.email`) }}</div>
          <div :class="$style['desc-data']">
            {{
              order.email.length > 20
                ? order.email.substring(0, 20) + "..."
                : order.email
            }}
            <IconCopy
              :class="$style['copy']"
              data-test="copy_field-copy"
              @click="copy(order.email)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Clipboard } from "@capacitor/clipboard";
import type { OrderHistory } from "~/api_gen";

const props = defineProps<{
  order: OrderHistory;
  mobileColor: boolean;
}>();
const { order } = toRefs(props);
const { t } = useI18n();

const notificationStore = useNotificationsStore();
const copy = async (value: string): Promise<void> => {
  try {
    await Clipboard.write({
      string: value.toString(),
    });
    notificationStore.showNotification({
      text: t("notifications.copy"),
      type: "success",
    });
  } catch (e: any) {
    //
  }
};
</script>

<style module lang="scss">
.order-topup__info {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: var(--spacing-2xl);
  gap: var(--spacing-2xl);
  background: var(--orders-history-items);
  border-radius: var(--radius-5xl);
  &-blocks {
    display: flex;
    gap: var(--spacing-sm);
    .info-block {
      display: flex;
      flex: 1;
      padding: var(--spacing-md) var(--spacing-2lg);
      gap: var(--spacing-4xs);
      border-radius: var(--radius-xl);
      background: var(--general-transparent-mid-5);
      .text-data {
        display: flex;
        flex-direction: column;
        .desc {
          font-size: 12px;
          color: var(--general-transparent-light-35);
        }
        .desc-data {
          font-size: 18px;
          white-space: nowrap;
          .copy {
            margin-left: 6px;
            width: 20px;
            height: 20px;
            fill: white;
            fill-opacity: 0.35;
            cursor: pointer;
          }
        }
      }
      .block-img {
        width: 48px;
        height: 48px;
        border-radius: var(--radius-sm);
        margin-right: var(--spacing-md);
      }
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
  }
}

.mobile-color {
  background: var(--general-underlay-3-lvl);
}
</style>
