<template>
  <div>
    <PageHeader />
    <LastOrderButton />
    <slot />
    <PageFooter />
    <Transition name="popup">
      <Auth v-if="userStore.authPopupIsRequired" />
    </Transition>
    <Transition name="popup">
      <CodeForm v-if="codesStore.isCodeFormRequired" />
    </Transition>
    <Transition name="popup">
      <PaymentsSuccessPopup v-if="successPopupRequired" />
    </Transition>
    <Transition name="popup">
      <PaymentsFailurePopup v-if="failurePopupRequired" />
    </Transition>
    <Transition name="popup">
      <CodeForm v-if="codesStore.isCodeFormRequired" />
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import Auth from "~/components/Auth.vue";
import LastOrderButton from "~/components/LastOrders/LastOrderButton.vue";

const userStore = useUserStore();
const paymentsStore = usePaymentsStore();
const codesStore = useCodesStore();

const { successPopupRequired, failurePopupRequired } =
  storeToRefs(paymentsStore);
</script>
