// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrderGame } from './OrderGame';
import {
    OrderGameFromJSON,
    OrderGameFromJSONTyped,
    OrderGameToJSON,
} from './OrderGame';
import type { OrderProduct } from './OrderProduct';
import {
    OrderProductFromJSON,
    OrderProductFromJSONTyped,
    OrderProductToJSON,
} from './OrderProduct';
import type { StatusEnum } from './StatusEnum';
import {
    StatusEnumFromJSON,
    StatusEnumFromJSONTyped,
    StatusEnumToJSON,
} from './StatusEnum';

/**
 * 
 * @export
 * @interface StandoffOrderHistoryTyped
 */
export interface StandoffOrderHistoryTyped {
    /**
     * 
     * @type {number}
     * @memberof StandoffOrderHistoryTyped
     */
    readonly id: number;
    /**
     * 
     * @type {StatusEnum}
     * @memberof StandoffOrderHistoryTyped
     */
    status: StatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof StandoffOrderHistoryTyped
     */
    readonly created: Date;
    /**
     * 
     * @type {string}
     * @memberof StandoffOrderHistoryTyped
     */
    total: string;
    /**
     * 
     * @type {string}
     * @memberof StandoffOrderHistoryTyped
     */
    rejectReason: string;
    /**
     * 
     * @type {Array<OrderProduct>}
     * @memberof StandoffOrderHistoryTyped
     */
    orderProducts: Array<OrderProduct>;
    /**
     * 
     * @type {OrderGame}
     * @memberof StandoffOrderHistoryTyped
     */
    readonly game: OrderGame;
    /**
     * 
     * @type {boolean}
     * @memberof StandoffOrderHistoryTyped
     */
    readonly isPaymentActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof StandoffOrderHistoryTyped
     */
    email: string | null;
    /**
     * 
     * @type {string}
     * @memberof StandoffOrderHistoryTyped
     */
    gameAccountId: string;
    /**
     * 
     * @type {string}
     * @memberof StandoffOrderHistoryTyped
     */
    gameAvatar: string | null;
    /**
     * 
     * @type {string}
     * @memberof StandoffOrderHistoryTyped
     */
    resourcetype: string;
}

/**
 * Check if a given object implements the StandoffOrderHistoryTyped interface.
 */
export function instanceOfStandoffOrderHistoryTyped(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "rejectReason" in value;
    isInstance = isInstance && "orderProducts" in value;
    isInstance = isInstance && "game" in value;
    isInstance = isInstance && "isPaymentActive" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "gameAccountId" in value;
    isInstance = isInstance && "gameAvatar" in value;
    isInstance = isInstance && "resourcetype" in value;

    return isInstance;
}

export function StandoffOrderHistoryTypedFromJSON(json: any): StandoffOrderHistoryTyped {
    return StandoffOrderHistoryTypedFromJSONTyped(json, false);
}

export function StandoffOrderHistoryTypedFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandoffOrderHistoryTyped {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'status': StatusEnumFromJSON(json['status']),
        'created': (new Date(json['created'])),
        'total': json['total'],
        'rejectReason': json['reject_reason'],
        'orderProducts': ((json['order_products'] as Array<any>).map(OrderProductFromJSON)),
        'game': OrderGameFromJSON(json['game']),
        'isPaymentActive': json['is_payment_active'],
        'email': json['email'],
        'gameAccountId': json['game_account_id'],
        'gameAvatar': json['game_avatar'],
        'resourcetype': json['resourcetype'],
    };
}

export function StandoffOrderHistoryTypedToJSON(value?: StandoffOrderHistoryTyped | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': StatusEnumToJSON(value.status),
        'total': value.total,
        'reject_reason': value.rejectReason,
        'order_products': ((value.orderProducts as Array<any>).map(OrderProductToJSON)),
        'email': value.email,
        'game_account_id': value.gameAccountId,
        'game_avatar': value.gameAvatar,
        'resourcetype': value.resourcetype,
    };
}

