<template>
  <Popup :class="$style['form-order-confirm']">
    <PopupHeader
      :show-back-btn="false"
      @hide="emit('hide')"
    >
      <slot name="title">{{ t("forms.order_user_confirm.title") }}</slot>
    </PopupHeader>
    <template
      v-for="item in gameProducts"
      :key="item.data.id"
    >
      <OrderCard
        v-if="item.amount"
        :product="item.data"
        :initial-amount="item.amount"
        :class="$style['form-order-card']"
        :compact="true"
        @change="productsStore.updateAmountUsingId($event)"
      />
    </template>
    <OrderBtnWrapper>
      <OrderBtn
        :content="t('products.products_order.confirm_order')"
        :total-amount="productsStore.totalAmount"
        :total-cost="productsStore.totalCost"
        @click="proceed"
      />
    </OrderBtnWrapper>
  </Popup>
</template>

<script lang="ts" setup>
import Popup from "~/components/Shared/Popup/Popup.vue";
import PopupHeader from "~/components/Shared/Popup/PopupHeader.vue";
import OrderCard from "~/components/Shared/Product/OrderCard.vue";
import OrderBtnWrapper from "~/components/Products/OrderBtnWrapper.vue";
import OrderBtn from "~/components/Products/OrderBtn.vue";

const { t } = useI18n();
const productsStore = useProductsStore();
const store = useUserStore();
const gameProducts = computed(() => productsStore.gameData?.products || []);
const emit = defineEmits<{
  (e: "hide"): void;
}>();

/**
 * Чтобы мы могли повторить заказ Стима, написано исключение. Если заказ SteamProduct,
 * тогда мы забираем сумму этого заказа и редиректим на страницу пополнения стима, где забираем
 * из квери параметра сумму и вставляем её в инпут сразу.
 *
 * Сделано так потому, что обычные карточки товаров имеют другую логику и представляют собой
 * отдельную сущность, нежели заказ Стим
 */
const proceed = () => {
  if (gameProducts.value[0].data.resourcetype === "SteamProduct") {
    const reOrderPrice = gameProducts.value[0].amount;
    const url = `/products/steam?reOrderPrice=${reOrderPrice}`;
    navigateTo(url);
    productsStore.showOrderConfirmForm = false;
    store.showLastOrderPopup = false;
    document.documentElement.style.overflow = "";
  } else {
    productsStore.showUserDataForm = true;
    productsStore.showOrderConfirmForm = false;
  }
};

watch(
  () => productsStore.totalAmount,
  (v) => {
    if (v <= 0) emit("hide");
  },
);
</script>

<style lang="scss" module>
.form-order-confirm {
  @media screen and (max-width: $mobile) {
    padding-bottom: 120px !important;
  }
}
.form-order-card {
  margin-bottom: var(--spacing-xs);
}
</style>
