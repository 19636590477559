<template>
  <svg
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.30615 13.011C10.4064 10.7127 13.8074 9.19752 15.5091 8.46547C20.3677 6.37532 21.3773 6.01223 22.0354 6.00012C22.1801 5.99761 22.5037 6.0347 22.7133 6.21062C22.8903 6.35917 22.939 6.55983 22.9623 6.70066C22.9856 6.8415 23.0146 7.16232 22.9916 7.41301C22.7283 10.2743 21.589 17.2178 21.0094 20.4225C20.7642 21.7785 20.2813 22.2332 19.8138 22.2776C18.7978 22.3743 18.0263 21.5832 17.0422 20.916C15.5024 19.8721 14.6325 19.2222 13.1378 18.2035C11.4105 17.0261 12.5303 16.3791 13.5147 15.3216C13.7723 15.0448 18.2488 10.8336 18.3354 10.4515C18.3463 10.4037 18.3563 10.2256 18.254 10.1316C18.1517 10.0376 18.0007 10.0697 17.8918 10.0953C17.7373 10.1316 15.2775 11.8132 10.5121 15.1402C9.81389 15.6361 9.18145 15.8777 8.61481 15.865C7.99014 15.8511 6.78851 15.4997 5.89523 15.1994C4.79958 14.831 3.92878 14.6363 4.0046 14.0107C4.0441 13.6848 4.47795 13.3516 5.30615 13.011Z"
      fill="white"
    />
  </svg>
</template>
