<template>
  <SharedPopupContainer @hide="hide">
    <SharedPopup data-test="popup-promo">
      <SharedPopupHeader @hide="hide">
        {{ $t("codes.title") }}
      </SharedPopupHeader>
      <div :class="$style.content">
        <CodeFormInput
          v-model="code"
          :inactive="isCodeActive"
          :current-code-data="currentCodeData"
          :error="codeError"
        />
        <div v-if="currentCodeData && currentGame">
          <SharedActionCard
            size="xs"
            :img="currentGame.logo || ''"
            :action="currentGame.title"
            :with-arrow="true"
            :discount="gameDiscountText"
            data-test="popup-promo-game"
            @click="handleGameClick"
          />
          <p :class="$style.hint">
            {{ !isCodeActive ? $t("codes.hint") : $t("codes.hint_activated") }}
          </p>
        </div>
        <SharedButtonPrimaryButton
          :class="$style.submit"
          :loading="loading"
          size="l"
          data-test="popup-promo-submit"
          @click="submitCode"
        >
          {{ !isCodeActive ? $t("codes.apply") : $t("codes.reset") }}
        </SharedButtonPrimaryButton>
      </div>
    </SharedPopup>
  </SharedPopupContainer>
</template>

<script setup lang="ts">
import { codeFormEventTarget, ACTION } from "./CodeForm/codeFormEventTarget";

const { t } = useI18n();
const codesStore = useCodesStore();
const { isCodeFormRequired } = storeToRefs(codesStore);
const code = ref("");
const codeError = ref("");
const loading = ref(false);
const userStore = useUserStore();
const notify = useNotify();

const isCodeActive = computed(() => {
  return Boolean(userStore.userData?.activePromoCode);
});
const currentCodeData = computed(() => {
  return userStore.userData?.activePromoCode || codesStore.currentCodeData;
});

const currentGame = computed(() => {
  return currentCodeData.value && currentCodeData.value.games?.length === 1
    ? currentCodeData.value.games[0]
    : null;
});

const gameDiscountText = computed(() =>
  currentCodeData.value
    ? `${toLocalNumber(Number.parseInt(currentCodeData.value.percent))}%`
    : "",
);

const hide = () => {
  isCodeFormRequired.value = false;
};
const init = () => {
  codesStore.currentCodeData = undefined;
};
const submitCode = async () => {
  if (loading.value) return;
  try {
    codeError.value = "";
    loading.value = true;
    if (isCodeActive.value) {
      await codesStore.deactivateCode();
    } else {
      await codesStore.activateCode(code.value);
    }
    codeFormEventTarget.dispatchEvent(new Event(ACTION));
    if (!isCodeActive.value) {
      hide();
    }
  } catch (e: any) {
    let errorData;
    if (e && e.response) {
      errorData = await e.response.json();
    }
    if (errorData && errorData.detail) {
      notify({ text: errorData.detail, type: "error" });
    } else if (errorData.code && Array.isArray(errorData.code)) {
      notify({ text: errorData.code[0], type: "error" });
    } else {
      notify({ text: t("codes.errors.apply_error"), type: "error" });
    }
  } finally {
    loading.value = false;
  }
};
onBeforeMount(() => {
  init();
});
const handleGameClick = async () => {
  const game = currentCodeData.value?.games[0];
  if (!isCodeActive.value) {
    try {
      await codesStore.activateCode(code.value);
      codeFormEventTarget.dispatchEvent(new Event(ACTION));
      if (game && "id" in game) {
        navigateTo(`/products/${game.id}`);
      }
      hide();
    } catch (e: any) {
      if (e.data && e.data.detail) {
        notify({ text: e.data.detail, type: "error" });
      }
      if (e.data && !("detail" in e.data) && "code" in e.data) {
        codeError.value = e.data.code[0] || e.data.code;
      }
    }
  } else {
    if (game && "id" in game) {
      navigateTo(`/products/${game.id}`);
    }
    hide();
  }
};
</script>

<style module>
.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4xl);
}
.hint {
  margin-top: var(--spacing-lg);
}
.submit {
  width: 100%;
}
</style>
