// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MooGoldLastOrderData
 */
export interface MooGoldLastOrderData {
    /**
     * 
     * @type {string}
     * @memberof MooGoldLastOrderData
     */
    moogoldUserId: string;
    /**
     * 
     * @type {number}
     * @memberof MooGoldLastOrderData
     */
    moogoldServer: number | null;
    /**
     * 
     * @type {string}
     * @memberof MooGoldLastOrderData
     */
    email: string | null;
}

/**
 * Check if a given object implements the MooGoldLastOrderData interface.
 */
export function instanceOfMooGoldLastOrderData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "moogoldUserId" in value;
    isInstance = isInstance && "moogoldServer" in value;
    isInstance = isInstance && "email" in value;

    return isInstance;
}

export function MooGoldLastOrderDataFromJSON(json: any): MooGoldLastOrderData {
    return MooGoldLastOrderDataFromJSONTyped(json, false);
}

export function MooGoldLastOrderDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MooGoldLastOrderData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'moogoldUserId': json['moogold_user_id'],
        'moogoldServer': json['moogold_server'],
        'email': json['email'],
    };
}

export function MooGoldLastOrderDataToJSON(value?: MooGoldLastOrderData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'moogold_user_id': value.moogoldUserId,
        'moogold_server': value.moogoldServer,
        'email': value.email,
    };
}

